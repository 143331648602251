import axios from 'axios';

export const userLogIn = (api_key, history)  => dispatch =>{
    axios.get("https://cust.3dmu.prototechsolutions.com/v1", {
        'headers': {
            'x-api-key': api_key
        }
    } )
    .then(
        response => {
            dispatch({type: 'LOGIN', api_key: api_key});
            history.push('/dashboard');
        }
    )
    .catch(
        err => {
            console.log(err);
            if(err.name === 'Error')
                alert('Wrong Credential');
            
            else {
                alert('Something went wrong, for more details see the console [press F12]');
            }
        }
    )
}