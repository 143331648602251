import React, { Component } from 'react';

import DataTable from 'react-data-table-component'

import axios from 'axios';
import { connect } from 'react-redux';

class Dashboard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            selectedRows: [],
            new_status: false,
            filter: '',
            filtercriteria: 'email'
        }

        this.onChangeClientStatus = this.onChangeClientStatus.bind(this);
        this.changePermission = this.changePermission.bind(this);
        this.changePlan = this.changePlan.bind(this);
        this.filterData = this.filterData.bind(this);
        this.showLog = this.showLog.bind(this);
        this.onSearchSriteriaSelection = this.onSearchSriteriaSelection.bind(this);
    }
   
    filterData (e) {
      this.setState({
        filter: e.target.value
      });
    }
    
    onChangeClientStatus (e) {
        console.log(e.target.checked);
        this.setState({
            new_status: e.target.checked
        });
    }

    onSearchSriteriaSelection(e){
      this.setState({
        filtercriteria: e.target.value
      });
    }

    showLog(e){
      let id = e.target.id;
      console.log(id);

    }

    changePlan (e) {
      let id = e.target.id;
      let new_plan = e.target.value;
      // let innerText = e.target.innerText;
      e.target.value = 'Processing';
      e.target.disabled = true;
      const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', 'Authorization': this.props.token },
        url: 'https://cust.3dmu.prototechsolutions.com/local/customers/manage-plan',
        data: {
          id: id,
          new_plan: new_plan
        }
      };

      axios(options)
        .then(
          response => {
            console.log(response.status);
            // window.location = '/';
          }
        )
        .catch(
          err => {
            console.log(err);
            alert('You have no privilege to perform this operation');
          }
        ).finally(
          f => { 
                 this.fetchDataFromDB ();
              }
        );
    }

    changePermission (e) {
      // let data = JSON.parse(e.target.value);
      let id = e.target.id;
      let value = e.target.checked;

      const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json' , 'Authorization': this.props.token},
        url: 'https://cust.3dmu.prototechsolutions.com/local/customers/modify-status',
        data: {
          id: id,
          new_status: value
        }
      };

      axios(options)
        .then(
          response => {
            console.log(response.status);
            // window.location = '/';
          }
        )
        .catch(
          err => {
            console.log(err);
            alert('You have no privilege to perform this operation');
          }
        ).finally(
          f => { 
             this.fetchDataFromDB ();
              }
        );
    }

    componentDidMount(){
      this.fetchDataFromDB ();
    }

    fetchDataFromDB () {
      const url = 'https://cust.3dmu.prototechsolutions.com/local/customers';
      const options = {
          method: 'GET',
          headers: { 'content-type': 'application/json', 'Authorization': this.props.token },
          url,
        };
      axios(options)
      .then(
          response => {
              this.setState({
                  clients: JSON.parse(response.data.body)
              })
            }
      )
      .catch(
          err => {
            console.log(err);
            alert('You have no privilege to perform this operation');
          }
      )
    }
   

    getDateFormate(registration_on) {
      const d = new Date(registration_on);
      const ddMMyyyy = d.getDate() + '/' + (d.getMonth() +1 ) + '/' + d.getFullYear() + ' : '+ d.getHours()  +':' + d.getMinutes() + ':' + d.getSeconds();

      return ddMMyyyy;
    }

    render() {
        let { clients } = this.state;
          // data filtering
        let filteredData = clients;

        filteredData.sort((second, first) => {
          var d1 = new Date(first.registration_on);
          var d2 = new Date(second.registration_on);
          if(d2 < d1){
            return 1;
          } else if(d2 > d1){
            return -1;
          } else {
            return 0;
          }

        });

        const conditionalRowStyles = [
            {
              when: row => row.enable_status ,
              style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            },
            {
              when: row => !row.enable_status ,
              style: {
                backgroundColor: 'rgba(240, 100, 100, 0.9)',
                color: 'white',
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            },
            {
              when: row => !row.is_verified ,
              style: {
                backgroundColor: '#7a5650',
                color: 'white',
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            }
          ];

        const columns = [
              {
                name: 'Name',
                selector: 'name',
                sortable: true,
                grow: 2,
              },
              {
                name: 'Email',
                selector: 'email',
                sortable: true,
                grow: 2,
              },
              {
                name: 'Company',
                cell: (data) => <>{data.additional_info !== undefined ? data.additional_info.organization_name  : 'Not Provided' }  </>,
                sortable: true,
                grow: 2,
              },
              {
                name: 'Registered On',
                cell: (row) => <> {row.registration_on !== undefined ? this.getDateFormate(row.registration_on)  : 'Not Provided' } </>,
                sortable: true,
                sortFunction: () =>  filteredData.sort((second, first) => {
                  var d1 = new Date(first.registration_on);
                  var d2 = new Date(second.registration_on);
                  if(d2 < d1){
                    return 1;
                  } else if(d2 > d1){
                    return -1;
                  } else {
                    return 0;
                  }
        
                }),
                grow: 2
              },
              {
                name: 'Plan',
                cell: (row) => (
                  <select name="plan" id={row.id} onChange={this.changePlan}>
                  <option value={row.user_plan}>{row.user_plan}</option>
                  <option value="all_feature_trial">all_feature_trial</option>
                  <option value="startup">startup</option>
                  <option value="trial">trial</option>
                </select>
                ),
                selector: 'user_plan',
                sortable: true,
                grow: 2,
              },
              {
                name: "API Key Enabled Status",
                cell: (row) => <> {row.enable_status ? (<><label className="switch">
                <input type="checkbox" id={row.id} defaultChecked onChange={this.changePermission}/>
                <span className="slider round"></span> 
              </label> </>) : 
                            (<><label className="switch">
                <input type="checkbox" id={row.id} onChange={this.changePermission}/>
                <span className="slider round"></span> 
              </label> </>)
               }</>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              }
        ];

        const customStyles = {
          headCells: {
            style: {
              fontSize: '105%',
            }
          },
          cells: {
            style: {
              fontSize: '110%',
            },
          },
        };
 
        if(this.state.filtercriteria === 'email'){
          filteredData = clients.filter(item => item.email && item.email.toLowerCase().includes(this.state.filter.toLowerCase()));
        } else  if(this.state.filtercriteria === 'id'){
          filteredData = clients.filter(item => item.id && item.id.toLowerCase().includes(this.state.filter.toLowerCase()));
        } else  if(this.state.filtercriteria === 'name'){
          filteredData = clients.filter(item => item.name && item.name.toLowerCase().includes(this.state.filter.toLowerCase()));
        } else  if(this.state.filtercriteria === 'api_key'){
          filteredData = clients.filter(item => item.api_key && item.api_key.toLowerCase().includes(this.state.filter.toLowerCase()));
        } else {
          filteredData = clients.filter(item => item.name && item.name.toLowerCase().includes(this.state.filter.toLowerCase()));
        }
       
        const ExpanableComponent = ({ data }) => <div id={data.api_key} className="container">
                  <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Usage</th>
                      <th>Id</th>
                      <th>API Key</th>
                      <th>Additional Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* <td><button id={data.id} onClick={this.showLog} key={data.id}>Log</button></td> */}
                      <td><a href={"https://dashboard.3dmeasureup.com/usage/"+JSON.stringify(data)} target="_b">Detail</a></td>
                      <td>{data.id}</td>
                      <td>{data.api_key}</td>
                      <AdditionalInfo additional_info={data.additional_info} is_verified={data.is_verified} registration_on={data.registration_on} key={data.id}/>
                    </tr>
                  </tbody>
                  </table>
              </div>;

        return (

            <div>
                <h1>Users</h1><br />
                <label style={{backgroundColor: 'rgba(63, 195, 128, 0.9)', width: '200px'}}>&nbsp;&nbsp;Activated&nbsp;</label> 
                <label style={{backgroundColor: 'rgba(240, 100, 100, 0.9)', width: '200px'}}>&nbsp;&nbsp;De-Activated&nbsp;</label>
                <label style={{backgroundColor: '#7a5650', width: '200px'}}>&nbsp;&nbsp;Un-Verified&nbsp;</label>

                <div style={{paddingLeft: '80%'}}> 
                <p ><select onChange={this.onSearchSriteriaSelection}>
                    <option value='email'>Email</option>
                    <option value='name'>Name</option>
                    <option value='id'>Id</option>
                    <option value='api_key'>API Key</option>
                   </select>
                
                <input type="text" placeholder="Search By Id" onChange={this.filterData}/></p> </div>

                <DataTable
                data={filteredData}
                columns={columns}
                conditionalRowStyles={conditionalRowStyles}
                pagination
                fixedHeader
                customStyles={customStyles}
                expandableRows
                expandableRowsComponent={<ExpanableComponent />}
                // onSort={handleSort}
                // sortFunction={sortByRegisteredDate}
                // }
                />
            </div>
        );
    }
}

function AdditionalInfo(data){
  console.log(data);
  const { additional_info,  registration_on, is_verified} = data;
  const d = new Date(registration_on);
  const ddMMyyyy = d.getDate() + '/' + (d.getMonth() +1 ) + '/' + d.getFullYear() + ' : '+ d.getHours()  +':' + d.getMinutes() + ':' + d.getSeconds();
  if(additional_info === undefined){
    return ('');
  }
  let additonalInfo = [];

  for (let [key, value] of Object.entries(additional_info)) {
    additonalInfo.push(key + ' : ' + value);
  }

  return (
        <td key={registration_on}>{additonalInfo.map(
          item => {
           return <p id={item.value} key={item.value}>{item}</p>
          }
        )}<p>registered_on: {ddMMyyyy}</p>
          <p>is_verified: {is_verified.toString()}</p>
        </td>
  )
  
}

const mapStateToProps = (state) => ({
  isLogin: state.isLogin,
  api_key: state.api_key,
  token: state.token
})

export default connect(mapStateToProps)(Dashboard);