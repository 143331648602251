import React, { Component } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { userLogIn } from '../../actions/authActions'

class SignIn extends Component {

    constructor(params) {
        super(params);
        this.state = {
            api_key: ''
        }

        this.onChangeInput = this.onChangeInput.bind(this);
        this.logIn = this.logIn.bind(this);
    }

    onChangeInput(e) {
        this.setState({
            api_key: e.target.value
        });
    }

    logIn(e) {
        this.props.userLogIn(this.state.api_key, this.props.history);
        e.preventDefault();
    }
    
    render() {
        return (
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
                <input type='text' onChange={this.onChangeInput}></input>
                <button  onClick={this.logIn}>Login</button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLogin: state.isLogin
})

SignIn.protoTypes = {
    userLogin: PropTypes.func.isRequired,
}

export default connect(mapStateToProps,{userLogIn}) (SignIn) ;