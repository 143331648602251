import React from 'react';
import { Route } from 'react-router-dom';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import store from './store'

 const PrivateRoute = ({component: Component, auth, ...rest}) => (
     
    <Route 
        {...rest}
        render = { props => 
            store.getState().token.length > 0 ? (
                <Component {...props} />
            ) : window.location = 'https://fo31vze4mj.auth.ap-south-1.amazoncognito.com/login?client_id=4vvjkbrna36jprb694vvd9cq88&response_type=token&scope=email+openid&redirect_uri=https://dashboard.3dmeasureup.com/'
        }
    />
)

export default PrivateRoute;